.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
}

button {
    border: 0;
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    background-color: green;
    color: white;
}
